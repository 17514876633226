import React, { useState, useEffect } from 'react';

const AnimatedBanner = () => {
  const [text, setText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [messageIndex, setMessageIndex] = useState(0);
  const [isWaiting, setIsWaiting] = useState(false);

  const messages = [
    "Connect with like-minded people.",
    "Find your perfect event matches.",
    "Build meaningful relationships!",
    "Discover networking opportunities!",
    "Create successful events.",
    "How much is a great connection worth?",
    "How do you find connections in a sea of people?",
    "Know what others are doing today, and what their best talents are!",
    "LinkedIn Profiles are static and don't tell an up to date story!",
    "Host connection events yourself to reconnect with your LinkIn Connections in meaningful ways.",
    "Imagine attending an event with 500 attendees?  How many can you actually meet and get to know?",
    "If an event has 100 people you likely miss connecting with 80, it it has 500 people you likely miss connecting with 480!",
    "How many events go on every week that you miss? Don't miss making the connections!",
    "Hosts can substantially monetize their events with Glonet by creating a Stripe Connect account from the create event screen.",
  ];

  useEffect(() => {
    const typeSpeed = 50; // Speed of typing
    const deleteSpeed = 30; // Speed of deleting
    const waitTime = 2000; // Time to wait between messages

    if (isWaiting) return;

    const currentMessage = messages[messageIndex];

    if (isDeleting) {
      if (text === '') {
        setIsDeleting(false);
        setMessageIndex((prev) => (prev + 1) % messages.length);
        setIsWaiting(true);
        setTimeout(() => setIsWaiting(false), 500);
        return;
      }

      const timeout = setTimeout(() => {
        setText(text.slice(0, -1));
      }, deleteSpeed);
      return () => clearTimeout(timeout);
    }

    if (text === currentMessage) {
      setIsWaiting(true);
      setTimeout(() => {
        setIsWaiting(false);
        setIsDeleting(true);
      }, waitTime);
      return;
    }

    const timeout = setTimeout(() => {
      setText(currentMessage.slice(0, text.length + 1));
    }, typeSpeed);

    return () => clearTimeout(timeout);
  }, [text, isDeleting, messageIndex, isWaiting, messages]);

  return (
    <div className="bg-gradient-to-r from-indigo-600 to-blue-500 text-white py-4 px-6 shadow-lg">
      <div className="max-w-4xl mx-auto flex items-center justify-center">
        <div className="text-center">
          <p className="text-lg sm:text-xl font-medium min-h-[2rem] relative">
            {text}
            <span className={`inline-block w-0.5 h-6 ml-1 bg-white ${isWaiting ? 'opacity-0' : 'animate-pulse'}`}>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default AnimatedBanner;
