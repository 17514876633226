// LandingPage.jsx
import React from "react";
import { Link, useLocation } from "react-router-dom";
import AnimatedBanner from "./AnimatedBanner"; 

const LandingPage = () => {
  const location = useLocation();

  // If we were navigated here from InvitationHandler, we have these in state:
  const { from, invitationCode, eventId } = location.state || {};

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-100 to-gray-300 py-6 flex flex-col justify-center sm:py-12">
      {/* Test Mode Banner */}
      <div className="sticky top-0 left-0 right-0 bg-yellow-400 text-black py-2 px-4 text-center font-bold z-50">
        Test mode. This site is currently in testing and not yet operational.
      </div>

      <AnimatedBanner />

      <div className="relative py-3 sm:max-w-xl sm:mx-auto mt-4">
        <div className="absolute inset-0 bg-gradient-to-r from-teal-400 to-blue-500 shadow-2xl transform
                        -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
        <div className="relative px-6 py-10 bg-white shadow-2xl sm:rounded-3xl sm:p-20">
          <div className="max-w-md mx-auto text-center">
            <img
              src="/MindMachineLearningLOGO.svg"
              className="h-14 sm:h-20 w-auto mx-auto animate-pulse"
              alt="MML Logo"
            />
            <h2 className="mt-8 text-4xl font-extrabold text-gray-900 tracking-tight">
              Glonet
            </h2>
            <p className="mt-4 text-lg text-gray-700">
              Flawlessly Matching Attendees for Every Event.
            </p>
          </div>

          <div className="mt-10 space-y-6">
            <div className="space-y-4">
              {/* Pass along from/invitationCode/eventId via 'state' */}
              <Link
                to="/signin"
                state={{ from, invitationCode, eventId }}
                className="w-full flex justify-center py-3 px-4 border border-transparent rounded-lg 
                           shadow-lg text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700
                           focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 
                           transition-transform transform hover:scale-105 duration-200"
              >
                Log In
              </Link>

              <Link
                to="/signup"
                state={{ from, invitationCode, eventId }}
                className="w-full flex justify-center py-3 px-4 border border-transparent 
                           rounded-lg shadow-lg text-base font-medium text-white bg-green-600 
                           hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 
                           focus:ring-green-500 transition-transform transform hover:scale-105 duration-200"
              >
                Sign Up
              </Link>
            </div>

            <div className="text-center">
              <Link
                to="/forgot-password"
                className="text-sm text-indigo-600 hover:text-indigo-500"
              >
                Forgot Password?
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
